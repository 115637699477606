<template>
<div>
<Upload />  
</div>
</template>
<script>
import Upload from '@/components/Upload.vue';
export default {
  name: 'home',
  components: {
    Upload
  },
};
</script>

<style>
  .logo {
    width: 20%;
    height: auto;
  }
</style>